import { render, staticRenderFns } from "./CafeteriaList.vue?vue&type=template&id=68b095d6&scoped=true&"
import script from "./CafeteriaList.vue?vue&type=script&lang=js&"
export * from "./CafeteriaList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68b095d6",
  null
  
)

export default component.exports