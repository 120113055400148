


















































import Vue from 'vue';
import DataTableMixin from '@/core/component/common/mixins/DataTableMixin';

export default Vue.extend({
  mixins: [DataTableMixin],

  name: 'LogDataTable',

  mounted() {
    this.load();
  },
});
