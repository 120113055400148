import { render, staticRenderFns } from "./GenericQnABoardItem.vue?vue&type=template&id=1153e06e&scoped=true&"
import script from "./GenericQnABoardItem.vue?vue&type=script&lang=js&"
export * from "./GenericQnABoardItem.vue?vue&type=script&lang=js&"
import style0 from "./GenericQnABoardItem.vue?vue&type=style&index=0&id=1153e06e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1153e06e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VDivider,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VSpacer})
