/**
 * This file is part of INU Cafeteria.
 *
 * Copyright 2022 INU Global App Center <potados99@gmail.com>
 *
 * INU Cafeteria is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * INU Cafeteria is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

/**
 * 식당 체크인 현황
 */
export default class Context {
  /**
   * (현재 시간대) 수용 가능 인원
   */
  capacity?: number;

  /**
   * (현재 시간대) 예약 인원
   */
  expected?: number;

  /**
   * (현재 시간대) 입장 인원
   */
  actual?: number;

  /**
   * 현재 상주 인원
   */
  total?: number;

  /**
   * 현재 타임슬롯 시작
   */
  timeSlotStart?: Date;

  /**
   * 현재 타임슬롯 끝
   */
  timeSlotEnd?: Date;

  static of(properties: Partial<Context>) {
    return Object.assign(new Context(), properties);
  }

  static fromResponse(raw: Record<string, never>) {
    const {capacity, expected, actual, total, timeSlotStart, timeSlotEnd} = raw;

    return Context.of({
      capacity,
      expected,
      actual,
      total,
      timeSlotStart: timeSlotStart ? new Date(timeSlotStart) : undefined,
      timeSlotEnd: timeSlotEnd ? new Date(timeSlotEnd) : undefined,
    });
  }

  /**
   * 현재 시간이 예약을 운영하는 시간대라면 true입니다.
   */
  isAvailable() {
    return this.timeSlotStart != null;
  }

  isUnavailable() {
    return !this.isAvailable();
  }
}
