import { render, staticRenderFns } from "./GenericToggleBoardItem.vue?vue&type=template&id=5fa4d930&"
import script from "./GenericToggleBoardItem.vue?vue&type=script&lang=js&"
export * from "./GenericToggleBoardItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCard,VDialog,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VSwitch})
